html {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Indie Flower', cursive;
  background-color: rgba(128, 214, 240, 0.2);
}

.navbar {
  border-bottom: 0.2px solid lightgray;
  font-size: 2em;
  &__elements {
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;

    & > *:nth-child(2) {
      cursor: pointer;
      transition: 0.25s ease-in transform;
      &:hover {
        transform: scale(0.9);
      }
    }
  }

  
}

.App {
  display: flex;
  flex-direction: column;
  & > *:nth-child(2) {
    flex-grow: 1;
  }
}

.board {
  width: 100%;

  &__elements {
    text-align: center;
    font-size: 1.5em;
    padding: 2rem 0;
  }

  &__element {
    font-size: 1.5em;
    width: max-content;
    &--hidden {
      margin-right: 0.5rem;
      opacity: 0;
      animation: 2s linear 2s forwards appear;
    }
  }
}

.welcome {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: lightgreen;
  z-index: 2;

  & > .form {
    font-family: 'Luckiest Guy', cursive;
    position: fixed;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%) scale(2);
    text-align: center;
    display: flex;
    flex-direction: column;
    & h2 {
      margin: 1rem;
      border: none;
    }
    & input {
      text-align: center;
      border: none;
      background-color: rgba(1,1,1,0);
    }

    & button {
      margin: 1rem 16px;
      border-radius: 1.5rem;
      border: none;
      padding: 0.25rem 0;
      transition: transform 1s;
      background-color: lightgray;

      &:hover {
        transform: scale(1.2);
        transition: transform 1s;
        cursor: pointer;
      }
    }
  }

  &--blur {
    filter: blur(10px)
  }
}

.loading {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  display: block;
  & > div {
    position: fixed;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-100%) scale(4);
    animation: 1s ease-out 0s blink infinite alternate;
  }
}

.add-form {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 300px;
  font-family: 'Indie Flower', 'Permanent Marker', cursive !important;

  & h2 {
    text-align: center;
  }

  & select {
    padding: 0.5rem;
    background-color: rgba(1,1,1,0);
    border: none;
  }

  input {
    padding: 0.5rem 1rem;
  }

  &__input {
    position: relative;
    margin: 0.5rem 0;

    & > div {
      width: 100%;
      display: flex;

      & > input {
        flex-grow: 1;
      }
    }

    & > span {
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(1.3) translate(30%,30%);
    }
  }

  button {
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    padding: 0.5rem 0;
  }
}

.shake {
  animation: 1s ease-out 0s shake 1;
}

.display-none {
  display: none;
}

.grid-col-2 {
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 0.5rem;

  @media (max-width: 600px) {
    grid-template: auto / 1fr;
  }
}

h1, h2 {
  margin: 0;
  text-align: center;
  font-family: 'Permanent Marker', cursive;
}

.messages {
  overflow: auto;
  max-height: 40vh;

  & > *:nth-child(2n - 1) {
    background-color: rgba(240, 128, 128, 0.2);
  }
}

.message {
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 2rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}